/* quick reset and base styles */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.row {
    margin: 0;
    padding: 0;
    border: 0;
}

.col,
.col-sm,
.col-md,
.col-lg {
    margin: 0;
    padding: 0;
    border: 0;
}

.img__img {
    width: 100%;
    overflow: hidden;
    transition: .2s;
}

/* relevant styles */
.img__wrap {
    position: relative;
    height: auto;
    width: 100%;
    border-radius: 5px;

}

.img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.8);
    /* align-items: bottom; */
    /* justify-content: center; */
    transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
    visibility: visible;
    opacity: 1;
}


.img__wrap:hover img {
    /* filter: grayscale(1) blur(5px) ; */
}


.img__description h2 {
    color: #fff;
    font-size: 120%;
    font-weight: 400;
    letter-spacing: 3px;
    word-wrap: break-word;
}

h2.title__github {
    font-size: 200%;
    text-align: center;
}

.img__description p {
    color: #fff;
    font-size: 85%;
    font-weight: 300;
}

a.link__layer {
    text-decoration: none;
}

.img__description {
    position: absolute;
    width: auto;
    bottom: 0;
    padding: 8% 5% 8% 5%;
    transition: .3s;
    transform: translateY(1em);
}

.img__wrap:hover .img__description {
    transform: translateY(0);
}

@media only screen and (max-width: 1500px) {

    .button_group,
    .icon_group {
        display: none;
    }
}

.button_group {
    margin: 25px 0 25px 0;
}

.button_group button {
    margin-right: 1.5%;
    font-size: 85%;
}

.icon_group {
    /* margin-top: 10px; */
    font-size: 35px;

}

/* .icon_group i,
ion-icon {
    color: #fff;
    font-size: 35px;
    margin-right: 15px
} */

a.project__link {
    color: #fff;
    font-weight: 400;
    letter-spacing: 3px;
    text-decoration: none;
    transition: .1s;
}

a.project__link:hover {
    color: #fff;
    font-weight: 800;
    /* letter-spacing: 2px; */
}

